import React, { FC } from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { gaButtonEvent } from './../../services/visitor-info';
import { Route } from '../../models/site-links';
import './cta-buttons.css';

interface buttonProps {
  buttonId: string;
  internal?: boolean;
  landing?: boolean;
  product?: boolean;
  link?: Route;
  anchor?: string;
  text: string;
  blue?: boolean;
  ggBlue?: boolean;
  className?: any;
  type?: any;
}
export const buttonClasses =
  'inline text-base border transition-colors py-4 px-8 my-4 bg-none cursor-pointer rounded-lg md:inline-flex md:w-auto focus:outline-none';
export const blueButtonClasses = 'text-blue border-blue hover:bg-blue hover:text-white';
export const whiteButtonClasses = 'text-white border-white hover:bg-white hover:text-blue';
export const ggButtonClasses = 'text-md';

const Button: FC<buttonProps> = (props) => {
  const buttonColorClasses = props.blue ? blueButtonClasses : whiteButtonClasses;
  if (props.internal) {
    return (
      <AniLink fade to={props.link ? props.link : ''}>
        <button
          onClick={() => gaButtonEvent(props.text, props.buttonId)}
          type={props.type ? props.type : 'button'}
          aria-label={props.text}
          className={` ${buttonClasses} ${buttonColorClasses} ${props.className}`}
        >
          {props.text}
        </button>
      </AniLink>
    );
  }
  if (props.landing) {
    return (
      <a target="_blank" href={props.link ? props.link : '#'}>
        <AnchorLink
          to={props.anchor}
          className={`${
            props.product ? 'gg-button-prod' : 'gg-button'
          } bg-blue-gg ${ggButtonClasses}`}
        >
          {' '}
          <span>{props.text}</span>
        </AnchorLink>
      </a>
    );
  }

  return (
    <a target="_blank" href={props.link ? props.link : '#'}>
      <button
        onClick={() => gaButtonEvent(props.text, props.buttonId)}
        type={props.type ? props.type : 'button'}
        aria-label={props.text}
        className={`${buttonClasses} ${buttonColorClasses} ${props.className}`}
      >
        {props.text}
      </button>
    </a>
  );
};
export default Button;
